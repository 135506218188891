








import { Component, Vue } from 'vue-property-decorator';
import PayAction from '@/components/PayAction.vue';

@Component({
  name: 'About',
  components: { PayAction }
})
export default class About extends Vue {}
